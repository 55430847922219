<template>
  <div class="goods">
    <!-- <van-swipe class="goods-swipe" :autoplay="3000">
      <van-swipe-item v-for="thumb in goods.picture" :key="thumb">
        <img :src="thumb" />
      </van-swipe-item>
    </van-swipe> -->

    <div class="goods-detail">
      <van-divider>商品详情</van-divider>
      <div v-html="goods.detail"></div>
    </div>

    <van-action-bar>
      <van-action-bar-icon icon="orders-o" text="订单" to="/order" />
      <!-- <van-action-bar-icon icon="cart-o" text="购物车" to="/cart" />
      <van-action-bar-button type="warning" text="加入购物车" @click="joinCart" /> -->
      <van-action-bar-button type="danger" text="立即购买" @click="gotoOrderConfirm" />
    </van-action-bar>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'

export default {
  data() {
    return {
      goods: {},
      num: 1
    }
  },

  created() {
    var params = this.$route.params
    if (params.id) {
      this.axios
        .post('/api/mall/loadGoodsDetail', {id: params.id})
        .then(res=> {
          console.log(res.data)
          if (res.data.success) {
            this.goods = res.data.data.detail
          } else {
            Toast('当前产品不存在！')
            this.$router.back()
          }
        })
    } else {
      Toast('产品参数错误！')
      this.$router.back()
    }
  },

  computed: {
    formatPrice() {
      return '¥' + (this.goods.price / 100).toFixed(2)
    }
  },

  methods: {
    joinCart() {
      this.axios
        .post('/api/mall/replaceCartGoods', { id: this.goods.id })
        .then(res=> {
          console.log(res.data)
          if (res.data.success) {
            Toast(res.data.message)
          } else {
            Toast('当前产品不存在！')
            this.$router.back()
          }
        })
    },

    gotoOrderConfirm() {
      localStorage.setItem('order', JSON.stringify([{goods: this.goods.id, num: this.num}]))
      this.$router.push({ name: 'orderConfirm' })
    }
  },
};
</script>

<style lang="less">
.goods {
  padding-bottom: 50px;

  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }

  &-detail {
    img {
      width: 100%;
      display: block;
    }
  }

  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }
}
</style>